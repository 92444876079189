import {
  ChainId,
  ConnectWallet,
  ThirdwebNftMedia,
  useAddress,
  useContract,
  useContractRead,
  useContractWrite,
  useNetwork,
  useNetworkMismatch,
  useOwnedNFTs,
  usePack,
  useSDK,
} from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import { configEnv, web3 } from "./configEnv";
import "./styles/Home.css";
import ERC20RewardBox from "./components/ERC20Reward";
import ERC1155RewardBox from "./components/ERC1155Reward";

export default function Home() {
  const sdk = useSDK();

  const address = useAddress();
  const [changeDetection, setChangeDetection] = useState<boolean>(false);
  const [openedPack, setOpenPacked] = useState<any>();
  const { contract } = useContract(configEnv?.NFTMinterContractAddress);
  // const  usdcContract = useContract(configEnv?.USDCContractAddress)
  const packContract = usePack(configEnv?.PackContractAddress);

  // const {
  //   mutate: approve,
  // } = useContractWrite(usdcContract?.contract, "approve");

  // const [usdcAllowance,setUsdcAllowance] = useState(null);
  // const [usdcAllowanceLoading,setUSDCAllowanceLoading] = useState(true);

  // useEffect(() => {
  //   (async ()=>{
  //     debugger
  //   if(address){
  //     const data = await usdcContract.contract?.call('allowance',address,configEnv?.NFTMinterContractAddress);
  //     debugger
  //   // const {data,isLoading} = useContractRead(usdcContract?.contract,'allowance',address,configEnv?.NFTMinterContractAddress)
  //   // setUsdcAllowance(data);
  //   }
  // })()
  // }, [address])

  const { mutate: mintMysteryBox1 } = useContractWrite(
    contract,
    "mintMysteryBox1"
  );

  const { data: mysteryBox1Price, isLoading: mysteryBox1PriceLoading } =
    useContractRead(contract, "mysteryBox1Price");

  const { mutate: mintMysteryBox2 } = useContractWrite(
    contract,
    "mintMysteryBox2"
  );

  const { data: mysteryBox2Price, isLoading: mysteryBox2PriceLoading } =
    useContractRead(contract, "mysteryBox2Price");

  const { data: nft1Balance, isLoading: nft1BalanceLoading } = useContractRead(
    packContract,
    "balanceOfBatch",
    [configEnv?.NFTMinterContractAddress, configEnv?.NFTMinterContractAddress],
    [5, 6]
  );

  const pack = usePack(configEnv.PackContractAddress);

  const { data: nfts, isLoading } = useOwnedNFTs(pack, address);

  const [network, switchNetwork] = useNetwork(); // Switch to desired chain
  const isMismatched = useNetworkMismatch(); // Detect if user is connected to the wrong network

  const openPackHandler = async (id: any) => {
    try {
      const packContract = await sdk?.getPack(configEnv?.PackContractAddress);

      const trx = await packContract?.open(id, 1);

      console.log(trx);
      setOpenPacked(trx);

      setChangeDetection(!changeDetection);
    } catch (err: any) {
      console.log(err);
      throw new Error(err?.message);
    }
  };

  useEffect(() => {
    // Check if the user is connected to the wrong network
    if (isMismatched && network && switchNetwork) {
      // Prompt their wallet to switch networks
      switchNetwork(ChainId.BinanceSmartChainTestnet); // the chain you want here
    }
  }, [isMismatched]);

  // useEffect(()=>{
  //   setInterval(()=>{
  //     setChangeDetection(!changeDetection)
  //     console.log('changing data')
  //   },2000)
  // },[])

  useEffect(() => {
    console.log("change detection", changeDetection);
  }, [changeDetection]);

  return (
    <div className="container">
      <main className="main">
        <div className="connect">
          <ConnectWallet />
        </div>
        <div className="buttons-sections">
          {/* {JSON.stringify(mysteryBox1PriceLoading)}
          {JSON.stringify(mysteryBox2PriceLoading)}
          {JSON.stringify(mysteryBoxPackage1PriceLoading)}
          {JSON.stringify(mysteryBoxPackage2PriceLoading)} */}

          {mysteryBox1PriceLoading || mysteryBox2PriceLoading ? (
            <div className="loading">Loading...</div>
          ) : (
            <>
              <button
                className="button-item"
                onClick={async () => {
                  const usdcContractTemp = await sdk?.getToken(
                    configEnv?.USDCContractAddress
                  );
                  const trx = await usdcContractTemp?.call(
                    "approve",
                    configEnv?.NFTMinterContractAddress,
                    web3.utils.hexToNumberString(mysteryBox1Price?._hex)
                  );
                  console.log(trx);
                  // const minterSmartContract = await sdk?.getContract(configEnv?.NFTMinterContractAddress);
                  // await minterSmartContract?.call("mintMysteryBox1");
                  await mintMysteryBox1([1]);
                  setChangeDetection(!changeDetection);
                }}
              >
                MINT ALL RARITIES x1 <br />
                $80
              </button>
              <button
                className="button-item"
                onClick={async () => {
                  const usdcContractTemp = await sdk?.getToken(
                    configEnv?.USDCContractAddress
                  );
                  const trx = await usdcContractTemp?.call(
                    "approve",
                    configEnv?.NFTMinterContractAddress,
                    web3.utils.hexToNumberString(mysteryBox2Price?._hex)
                  );
                  console.log(trx);
                  // const minterSmartContract = await sdk?.getContract(configEnv?.NFTMinterContractAddress);
                  // await minterSmartContract?.call("mintMysteryBox2");
                  await mintMysteryBox2([1]);
                  setChangeDetection(!changeDetection);
                }}
              >
                MINT ONLY 1% ISLANDS x1
                <br /> $350
              </button>
            </>
          )}
        </div>

        {openedPack &&<h4>Opened Reward</h4>}
        {openedPack &&
          openedPack?.erc20Rewards &&
          openedPack?.erc20Rewards?.length > 0 && (
            <>
              <h3>ERC20 Tokens</h3>
              <div className="">
                {openedPack?.erc20Rewards?.map((reward: any, i: number) => (
                  <ERC20RewardBox reward={reward} key={i} />
                ))}
              </div>
            </>
          )}

        {openedPack &&
          openedPack?.erc1155Rewards &&
          openedPack?.erc1155Rewards?.length > 0 && (
            <>
              <h3>ERC1155 Tokens</h3>
              <div className="">
                {openedPack?.erc1155Rewards.map((reward: any, i: any) => (
                  <ERC1155RewardBox reward={reward} key={i} />
                ))}
              </div>
            </>
          )}
      </main>
      <div>
        {!isLoading ? (
          <div className="nftBoxGrid">
            {nfts
              ?.filter((item) => ["5", "6"].includes(item?.metadata?.id))
              ?.map((nft, index) => {
                console.log(JSON.stringify(nft));
                return (
                  <div className="nftBox" key={index}>
                    <h1>{nft?.quantityOwned}</h1>
                    <ThirdwebNftMedia
                      metadata={nft?.metadata}
                      className="nftMedia"
                    />
                    <h3>{nft?.metadata?.name}</h3>
                    <button
                      className={`mainButton spacerBottom`}
                      onClick={() => openPackHandler(nft?.metadata?.id)}
                    >
                      Open
                    </button>
                  </div>
                );
              })}
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}
