import { ThirdwebNftMedia, useMetadata, useToken } from "@thirdweb-dev/react";
import React, { useEffect } from "react";

type Props = {
  reward: {
    contractAddress: string,
    quantityPerReward: string | number,
  },
};

export default function ERC20RewardBox({ reward }: Props) {
  const token = useToken(reward.contractAddress);
  const { data } = useMetadata(token);
  useEffect(() => {
    console.log('data',data);
  }, [data])
  
  return (
    <div className="nftBox">
      {data && (
        <>
          <ThirdwebNftMedia metadata={data?.image} className="nftMedia" />
          <h3>{data?.name}</h3>
          <p>Amount: {reward.quantityPerReward}</p>
        </>
      )}
    </div>
  );
}